.course_container {
    background-image: url(../assets/assets/images/course-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 15px 0px;
    border-bottom: 2px solid rgb(242 127 12);
}
.course_box {
    background-color: rgb(255 255 255);
    border: 1px solid rgb(255 234 213);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    margin: 15px 0px;
}
.course_icon {
    background-color: rgb(248, 243, 243);
    border-radius: 4px;
    padding: 15px;
    margin-right: 35px;
}
.course_txt {
    font-weight: 400;
    font-size: 18px;
    color: rgb(56 56 56);
}
.course_txt span {
    color: rgb(242 127 12);
}
.footer_container {
    background-color: rgb(239 239 239);
    padding-top: 45px;
    padding-bottom: 30px;
}
.footer_logo {
    margin-bottom: 10px;
}
.footer_address {
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: rgb(97 109 126);
    position: relative;
    padding-left: 20px;
}
.footer_address i {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 18px;
}
.footer_head {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: rgb(0,0,0);
    margin-bottom: 12px;
}
.footer_list li {
    list-style-type: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgb(97 109 126);
    margin-bottom: 10px;
}
.footer_list li a {
    color: rgb(97 109 126);
    text-decoration: none;
}
.footer_list li i {
    margin-right: 10px;
    color: rgb(97 109 126);
}
.footer_media {
    margin-top: 10px !important;
}
.footer_media li {
    list-style-type: none;
    display: inline-block;
    margin-right: 8px;
}
.footer_media li a {
    background-color: rgb(217 217 217);
    color: rgb(97 109 126);
    border-radius: 3px;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    outline: none;
}
.footer_media li a i {
    color: rgb(117, 129, 149);
}
.copyright_container {
    background-color: rgb(0, 57, 110);
    padding: 12px 0px;
    color: rgb(255,255,255);
    font-weight: 400;
    font-size: 15px;
}
.copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright_container a {
    color: rgb(255,255,255);
    text-decoration: none;
}
.dropdown {
    position: absolute;
    width: 250px;
    background-color: rgb(255,255,255);
    top: 33px;
    left: 0px;
    border-top: 3px solid rgb(0, 57, 110);
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    z-index: 9;
    transform: translateY(30%);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-in-out;
}
.menuH li:hover .dropdown{
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
    transition: 0.5s all ease-in-out;
}
.dropdown li {
    border-bottom: 1px solid rgb(241, 241, 241);
    float: left;
    width: 100%;
    padding: 0px !important;
}
.dropdown li a {
    font-size: 15px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    text-decoration: none !important;
    outline: none !important;
    color: #00396e;
    padding: 12px 15px;
    display: block;
    line-height: 19px;
}
.menuH li a i {
    font-size: 13px;
    margin-left: 5px;
}




@media (min-width:320px) and (max-width:767px) {
.footer_address {
    padding-left: 0px;
}

.footer_box  {
    padding-left: 20px;
}




}