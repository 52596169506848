@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
body {
    font-family: 'Roboto', sans-serif;
}
ul {
    padding: 0px;
    margin: 0px;
}
/* .main_container{
    width:100%;
} */
.flt {
    float: left;
    width: 100%;
}
.header_containers {
    padding: 8px 0px;
}
.menu_containers {
    background-color: rgb(0 57 110);
    padding: 12px 0px;
    margin-bottom: 40px;
}
.menuH li {
    list-style-type: none;
    display: inline-block;
    padding: 0px 24px;
    position: relative;
}
.menuH li::before {
    content: '';
    height: 15px;
    width: 1px;
    background-color: rgb(255,255,255);
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translatey(-50%);
}
.menuH li:first-child {
    padding-left: 0px !important;
}
.menuH li:first-child::before {
    display: none;
}
.menuH li a {
    color: rgb(241, 232, 234);
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    outline: none;
}
.menuH ul li a{
    color:black;
}
.page_container {
    padding: 40px 0px 85px 0px;
}
.banner_container {
    background-image: url(../assets/assets/images/inner-banner.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: rgb(255,255,255);
    margin-bottom: 40px;
}