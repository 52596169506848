.flt {
  float: left;
  width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  padding: 0px;
  margin: 0px;
  float: left;
  width: 100%;
}

.form1 {
    background: #FFFFFF;
    border: 1px solid #f27f0c38;
    box-shadow: 0px 0px 15px rgb(0 65 90 / 15%);
    border-radius: 8px;
    padding: 15px 20px 54px 20px;
  }
  
  .form1 form h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #00415A;
    margin-bottom: 20px;
  }
  
  .form1 form label
  { 
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    align-items: center;
    text-transform: uppercase;
    color: #00415A;
    margin-bottom: 5px;
  
  }
  .form-group {
    margin-bottom: 25px;
  }
  
  .form1 form input placeholder {
    font-size: 5px;
  }
  
  .form1 input,
  .form1 select,
  .form1 textarea {
    border: 1px solid #f27f0c;
    border-radius: 5px;
    width: 100%;
    background: #F5F5F5;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 24px;
    outline: none;
  }
  
  .star{
    color: #c50b0bb8;
  }
  
  .cours{
    color: orange;
  }

  .image_format{
    color:#F27F0C;
    font-size: 15px;
  }
  
  .program{
    color: orange;
  }

  .note{
    color: #00415A;
  }
  
  .documents{
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #00415A;
    margin-bottom: 20px;
  }

  .invalid-feedbacks{
    /* display: none;   */
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
  }

  .fees{
    float: right;
    border: 1px solid rgb(233, 186, 31);
    width:200px;
    padding:10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  /* .submit_button{
    float: right;
    border: 1px solid rgb(233, 186, 31);
    width:200px;
    padding:10px;
  }
   */
  .submit{
    text-align: right;
    padding-right: 20px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .submit button{
    width:200px;
    background: #F27F0C;
    padding: 10px 35px;
    border: none;
    color: #FFFFFF;
  }
 
.image_preview img{
    width: 100%;
    margin-top: 10px;
    border: 1px solid rgb(206 212 218);
    border-radius: 4px;
    height:250px;
}

.user_id img{
  width: 100%;
  margin-top: 10px;
  border: 1px solid rgb(206 212 218);
  border-radius: 5px;
  float: right;
}

@media only screen and (max-width: 600px) {
  .user_id img{
    width:120%;
  }
}

.users{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  padding: 20px 25px;
  border: 1px solid #f27f0c38;
  border-radius: 4px;
}

.users_list{
  margin: 0px;
  padding: 25px;
}

  .user_list li {
  list-style-type: none;
  width: 100%;
  color: rgb(0,0,0);
  float: left;
  margin-bottom: 15px;
}

  .user_list li span {
    float: right;
    padding: 0px 20px;
    width: 50%;
  }

  .user_list li d {
    float: left;
    width: 50%;
    }


 .amount{
  float: right;
 }  
 
 .amounts{
  float: right;
  border: 1px solid rgb(233, 186, 31);
  width:350px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#F27F0C;
  font-size: 20px;
 }
  
 .header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.blog_head {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #383838;
  background-color: #F9F9F9;
  padding: 12px 25px;
}

.blog_title {
  font-weight: 300;
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
  margin-left: 5px;
}

.personal_details {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

ul.user_list {
  width: 80%;
}

.user_id {
  width: 20%;
}


.dropdownss{
  /* box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  padding: 20px 25px;
  border: 1px solid #f27f0c38;
  border-radius: 4px;
  margin-bottom: 10px;
  width:97%;
  margin-left:20px; */

  border: 1px solid #f27f0c38;
  border-radius: 5px;
  width:100%;
  margin-bottom: 25px;
  color: #616D7E;
  background: #F5F5F5;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 24px;
  outline: none;
}

.dropdown-item{
  list-style:disc;
}
.dropdown-menu{
  background: #F9F9F9;
  margin-top: 150px;
  width:100%;
  padding: 10px;
}

.dropdown-toggle::after {
  position: absolute;
  right: 0%;
  margin-right: 100px;
  font-size: 30px;
  margin-top: 10px;
}

.button{
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}


.course_list{
  margin: 10px;
  margin-left: -1px;
  padding: 20px;
}

.course_list li {
  list-style-type: none;
  width: 100%;
  color:#F27F0C;
  float: left;
  border:1px solid rgb(219, 218, 218);
  padding:20px;
  margin-top: 10px;
  border-radius: 5px;
}

.declarations{
  font-weight: 450;
  margin-left:30px;
}

.feeInclude{
  font-weight: 450;
  margin-left:30px;
}

.feeLabel{
  color: #00415A;
}

.checkbox label{
  color: #00415A;
  font-weight: 500;
}


.flt {
  float: left;
  width: 100%;
}

.popup-box {
  position: fixed;
  /* background: transparent; */
  background: #3e3c3cd4;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.box {
 
  padding: 50px 30px;
  position: relative;
  width: 60% !important;
  height: 400px !important; 
  left:20%;
  top:50px;
  transform: translateY(-15%);
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  margin: 50px auto;
  /* overflow: auto; */

}


.head {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  align-items: center;
  text-align: center;
  color: #F27F0C;
}

.para {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #00415A;
}

.para2 {
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  color: #454545;
}

.imgg {
  text-align: center;
}


.sub_btn {
  text-align: center;
}

.sub_btn  button{
 color: #fff;
  background: #F27F0C;
  border-radius: 8px;
  padding: 10px 60px;
  border: none;
}

@media (min-width:820px) and (max-width:950px){
  .dropdown-toggle::after {
    position: absolute;
    right: 0%;
    margin-right: 20px;
    font-size: 30px;
    margin-top: 10px;
  }
}

@media (min-width:320px) and (max-width:767px)
  {
    .feeLabel{
      color: #00415A;
      font-size:16px;
    }

    .form1 select {
      border: 1px solid #f27f0c;
      border-radius: 5px;
      width: 100%;
      background: #F5F5F5;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      padding: 8px 5px;
      outline: none;
    }

    .documents{
      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: #00415A;
      margin-bottom: 20px;
    }

    .image_format{
      font-size: 12px;
    }
    
    .dropdown-toggle::after {
      position: absolute;
      right: 0%;
      margin-right: 16px;
      font-size: 25px;
      margin-top: 10px;
    }

    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      font-weight: 400;
      font-size: 14px;
      white-space: normal;
  }

    .dropdownss{
      border: 1px solid #f27f0c38;
      border-radius: 5px;
      width:100%;
      margin-bottom: 25px;
      color: #616D7E;
      background: #F5F5F5;
      font-weight: 300;
      font-size: 12px;
      line-height: 24px;
      padding: 15px 2px;
      outline: none;
    }

    .menuH li {
      padding: 0px 8px;
    }

    .personal_details {
      display: table;
  }

    .user_id {
      display: table-header-group;
  }

  .user_id img {
    width: 100%;
    margin: 20px 0px !important;
}

    .dropdown-menu{
      background: #F9F9F9;
      margin-top: 150px;
      width:100%;
      padding: 10px;
    }

    .blog_head {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #383838;
      background-color: #F9F9F9;
      padding: 12px 15px;
    }

    .users{
      box-shadow: 0px 0px 6px rgba(105, 35, 35, 0.48);
      padding: 20px 12px;
      border: 1px solid #f27f0c38;
      border-radius: 4px;
      margin: 5px;
    }

    .user_list li span {
      font-size: 14px;
      float: right;
      padding: 0px 12px;
      width: 60%;
    }
  
    .user_list li d {
      font-size: 15px;
      float: left;
      width: 40%;
      }

      .container p {
        text-align: center;
    }

  .form1 form h3 {
    font-weight: 500;
    font-size: 19px;
    line-height: 15px;
   
  }

  .form1 input {
    padding: 3px 3px;
  }
  
  .form1 input::placeholder, .form2 input::placeholder{
  
  font-size: 12px !important;
  }
  
  .checkbox label {
    font-size: 18px !important;
    line-height: 15px !important;
    margin-bottom: 0px;
    margin-right: 0px;
    text-transform: none !important;
  }
  
  .sub_txt {
    text-align: right;
    padding-right: 20px;
    margin-top: 10px !important;
  }
  
  .sub_txt button {
    padding: 6px 26px;
  }
  .form2 {
    margin-top: 20px;
  }
  
  .contact ul li {
      margin: 0px 5px;
      display: inline;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: #00415A;
  }
  
  .foot_text {
  
    font-size: 10px;
  
  }
  .box {
    padding: 45px 8px;
    position: relative;
    width: 85% !important;
    height: 350px !important;
    left: 8%;
    top: 52px;
    transform: translateY(-10%);
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 5px;
    margin: 50px auto;
    /* overflow: auto; */
  }
  .imgg img {
    /* width: 250px;
    height: 100px; */
}
.head {
  font-weight: 500;
  font-size: 15px !important;
  line-height: 35px;
  align-items: center;
  text-align: center;
  color: #F27F0C;
  margin-top: 10px;
}

.para {
font-weight: 400;
font-size: 13px;
line-height: 17px;
text-align: center;
color: #00415A;
}

.para2 {
font-weight: 400;
font-size: 12px;
line-height: 16px;
text-align: center;
color: #454545;
}
.sub_btn button {
color: #fff;
background: #F27F0C;
border-radius: 6px;
padding: 7px 45px;
border: none;
}

}
